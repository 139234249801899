import React from "react";
import Map from "./Helpers/map";

interface Props {
  children: React.ReactNode;
  stationData: any;
  dataEnabled: boolean;
  location: number;
  grid_size: number;
  sponsor_plate: any;
  config: any;
}

const locations = [
  "hidden",
  ""
];
const grid = [
  "lg:w-48 lg:bottom-12 lg:top-12",
  "grid grid-cols-2 lg:w-96",
  "grid grid-cols-3 lg:w-[35rem]",
];

const DataPanel: React.FC<Props> = ({
  children,
  stationData,
  config,
  dataEnabled,
  location,
  grid_size,
  sponsor_plate,
}) => {
  if (dataEnabled) {
    return (
      <div
        className={`${grid[grid_size]}  	${
          locations[location + 1]
        } bg-white dark:bg-black lg:bg-opacity-0 dark:lg:bg-opacity-0 backdrop-blur-lg lg:rounded-lg  cursor-pointer lg:overflow-y-scroll noscrollbar max-h-svh`}>
        {children}
      </div>
    );
  } else {
    return (
      <div
        className={` ${
          locations[location + 1]
        } ${grid[grid_size]} bg-white dark:bg-black lg:bg-opacity-0 dark:lg:bg-opacity-0 backdrop-blur-lg lg:rounded-lg  cursor-pointer lg:overflow-y-scroll noscrollbar max-h-svh  `}>
        <div
          className={`bg-gray-600 bg-opacity-70 rounded-md ${config.panel_height} ${config.panel_padding} ${config.panel_margin} flex items-center justify-center text-sm text-white text-center`}>
          Data Link Down
        </div>
        {sponsor_plate}

        <Map
          location={stationData.geo}
          grid_size={grid_size}
          config={config}
          layers={{ radar: true, spc_day_1: false, alerts: true }}
        />
        
      </div>
    );
  }
};

export default DataPanel;
