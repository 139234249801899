//@ts-nocheck
import React, { useEffect, useState } from 'react';
import VideoJS from './VideoJS';

const HLSBackground = React.memo(({ posterUrl, videoUrl }) => {
  const playerRef = React.useRef(null);
  const [playerStatus, setPlayerStatus] = useState(true);
  const [retry, setRetry] = useState(0);

  const videoJsOptions = React.useMemo(() => ({
    autoplay: true,
    muted: true,
    liveui: true,
    fill: true,
    playsinline: true,
    controls: true,
    poster: posterUrl,
    sources: [{
      src: videoUrl,
      type: 'application/x-mpegurl'
    }]
  }), [posterUrl, videoUrl, retry]);

  const handlePlayerReady = (player) => {
    playerRef.current = player;

    player.on("waiting", () => {
      setPlayerStatus(true);
    });

    player.on("dispose", () => {
      setPlayerStatus(false);
    });

    player.on("error", () => {
      setPlayerStatus(false);
    });

    player.on("play", () => {
      setPlayerStatus(true);
    });

    player.on("playing", () => {
      setPlayerStatus(true);
    });
  };

  const handleRetry = () => {
    setPlayerStatus(false); // Hide the "Video Link Down" message
    setRetry(prev => prev + 1); // Increment retry to force re-render
  };

  return (
    <div className='w-fill lg:h-dvh overflow-hidden'>
      {playerStatus ? null : (
        <div className="absolute z-10 w-full lg:h-dvh bg-black aspect-video rounded-lg overflow-hidden">
          <object className='w-full h-full' style={{ filter: "blur(50px) brightness(20%)" }} data='/fallback_background.gif' type="image/gif">
            <img
              style={{ filter: "blur(10px)" }}
              src={posterUrl}
            />
          </object>
          <div className="absolute top-0 bottom-0 left-0 right-0 flex flex-col items-center justify-center text-white text-lg font-black">
            <div>Video Link Down</div>
            <button
              onClick={handleRetry}
              className="mt-4 px-4 py-2 bg-gray-600 hover:bg-gray-700 rounded-md"
            >
              Retry
            </button>
          </div>
        </div>
      )}
      <VideoJS options={videoJsOptions} onReady={handlePlayerReady} />
    </div>
  );
});

export default HLSBackground;
