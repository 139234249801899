//@ts-nocheck
import React, { useRef, useEffect, useState } from "react";
import { interpolateColor, lightenColor, darkenColor, createLinearGradient } from '../utils/colorUtils';

import { WiSmog } from "react-icons/wi";



const rangeMultiplier = 5;
const colorArray = [
  [
    [0, [71,230,14, 255]],
    [50, [71,230,14, 255]],

    [51, [250,255,41, 255]],
    [100, [250,255,41, 255]],

    [101, [240,133,16, 255]],
    [150, [240,133,16, 255]],

    [151, [255,0,0, 255]],
    [200, [255,0,0, 255]],

    [201, [135,58,192, 255]],
    [300, [135,58,192, 255]],

    [301, [131,33,47, 255]],
    [5000, [131,33,47, 255]],
    
  ],
];


export default function PM25_Plate({ pm25, pm25_aqi, config, range, trendline, clicked}) {
  const [minValue, setMinValue] = useState<number | null>(null);
  const [maxValue, setMaxValue] = useState<number | null>(null);
  const [linePath, setLinePath] = useState<string | null>(null);

  useEffect(() => {
    if (range && trendline) {
      const min = Math.round(range.min);
      const max = Math.round(range.max);
      setMinValue(min);
      setMaxValue(max);

      const step = 100 / (trendline.length - 1);
      setLinePath(
        trendline
          .map((value, index) => {
            const x = (index * step).toFixed(2);
            const y = (
              100 -
              ((value - (min - rangeMultiplier)) /
                (rangeMultiplier + max - (min - rangeMultiplier))) *
                100
            ).toFixed(2);
            return `${x} ${y}`;
          })
          .join(" ")
      );
    } else {
      setMinValue(null);
      setMaxValue(null);
    }
  }, [trendline, range]);

  if (
    pm25 === null ||
    pm25_aqi === null ||
    minValue === null ||
    maxValue === null ||
    linePath === null
  ) {
    //return <LoadingPlate/>; // Placeholder or loading indicator
    return null //Used for non forced panels
  }

  

  const color = interpolateColor(Number(pm25_aqi), colorArray);
  const linearGradient = createLinearGradient(
    color,
    Number(pm25_aqi),
    Number(minValue),
    Number(maxValue)
  );

  return (
    <div
    className={`relative ${config.panel_height} grid items-center bg-black bg-opacity-70 rounded-md ${config.panel_padding} ${config.panel_margin}  transition-all duration-1000 hover:brightness-50 hover:drop-shadow-lg cursor-pointer`}
    style={{
        background: `${darkenColor(color, 50)}`,
        position: "relative",
      }}
      onClick={() => clicked('pm25')}
    >
      <svg
        className="absolute inset-0 rounded-md"
        viewBox="0 0 100 100"
        preserveAspectRatio="none"
        style={{ width: "100%", height: "100%"}}
      >
        <polygon
          points={`0 100, ${linePath}, 100 100`}
          fill={darkenColor(color, 25)}
          opacity="1"
        />
      </svg>
      <div className="absolute left-2 flex items-center text-white z-10">
        <WiSmog size={32} />
      </div>
      <div className="absolute right-2 text-3xl text-right z-10">{Math.round(pm25)}</div>
      <div className="text-xs text-right absolute bottom-0 inset-x-0 p-1 z-10">
        µg/m3
      </div>
      <div className="text-xs text-left absolute bottom-0 inset-x-0 p-1 z-10">
        {minValue} | {maxValue}
      </div>
      <div className="text-xs text-left absolute top-0 inset-x-0 p-1 z-10">
        PM2.5
      </div>
    </div>
  );
}
