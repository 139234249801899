//@ts-nocheck
import React, { useEffect, useState, useRef, useReducer } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import StandardBackground from "../StandardBackground";
import DataPanel from "../DataPanel";
import TemperaturePlate from "../DataPlates/temperature_plate";
import HumidityPlate from "../DataPlates/humidity_plate";
import WindPlate from "../DataPlates/wind_plate";
import WindDirectionPlate from "../DataPlates/wind_direction_plate";
import UVPlate from "../DataPlates/uv_plate";
import SolarRadiationPlate from "../DataPlates/solar_radiation_plate";
import RainRate from "../DataPlates/rainrate_plate";
import RainDay from "../DataPlates/rainday_plate";
import DewPointPlate from "../DataPlates/dew_plate";
import PressurePlate from "../DataPlates/pressure_plate";
import PM25_Plate from "../DataPlates/pm25_plate";
import PM10_Plate from "../DataPlates/pm10_plate";
import BackButton from "../Controls/BackButton";
import StationNamePlate from "../Controls/StationNamePlate";
import StationAlertPlate from "../Controls/StationAlertPlate";
import WetBulbGlobePlate from "../DataPlates/wet_bulb_globe_temp";
import LightningStrikePlate from "../DataPlates/lightning_strike_plate";
import WindChillPlate from "../DataPlates/wind_chill_plate";
import LightningIntensityPlate from "../DataPlates/lightning_intensity_plate";
import LoadingPlate from "../DataPlates/loading_plate";
import LoadingPage from "../LoadingPage";
import AirportPlate from "../DataPlates/airport_plate";

import Map from "../Helpers/map";

import ImagePlate from "../DataPlates/image_plate";

import AirQualityPlate from "../Controls/AirQualityPlate";

import StationSettingsPlate from "../Controls/stationSettingsPlate";
import TimeIntervalPicker from "../Controls/TimeIntervalPicker";
import DataPanelLocationPicker from "../Controls/DataPanelLocationPicker";
import DataPanelSizePicker from "../Controls/DataPanelSizePicker";
import VideoServerPicker from "../Controls/VideoServerPicker";

import HLSBackground from "../VideoPlayers/HLSBackground";
import HLSPlayer from "../VideoPlayers/HLSPlayer";
import LineGraphModal from "../Graphs/dataModal";
import AlertModal from "../Alerts/alertModal";

const UPDATE_VALUE = "UPDATE_VALUE";
const REPLACE_DATA = "REPLACE_DATA";
const UPDATE_LIGHTNING_DATA = "UPDATE_LIGHTNING_DATA";

const config = {
  panel_padding: "p-1",
  panel_margin: "m-1",
  panel_height: "h-16",
};

function newDataReducer(state, action) {
  if (state === null && action.type === UPDATE_VALUE) {
    return state;
  }

  switch (action.type) {
    case REPLACE_DATA:
      return action.payload;

    case UPDATE_VALUE:
      const { parameter, value } = action.payload;
      const parameterData = state?.[parameter] ?? {
        value: 0,
        range: { min: 0, max: 0 },
      };
      const newValue = parseFloat(value);
      const newMin = Math.min(newValue, parameterData.range.min);
      const newMax = Math.max(newValue, parameterData.range.max);

      return {
        ...state,
        [parameter]: {
          ...parameterData,
          value: newValue,
          range: { min: newMin, max: newMax },
        },
      };

    case UPDATE_LIGHTNING_DATA:
      const updatedState = { ...state };
      if (updatedState && action.payload.parameter === "lightning_distance") {
        // Add logic to update lightning data if needed
      }
      return updatedState;

    default:
      return state;
  }
}

export default function Station(props) {
  const stationDataFetched = useRef(false);
  const historicalDataFetched = useRef(false);
  const lightningDataFetched = useRef(false);
  const eventListenerConnected = useRef(false);
  const updateData = useRef(null);
  const eventSourceRef = useRef(null);
  const reconnectIntervalRef = useRef(null);
  const [loadingText, setLoadingText] = useState("");
  const [obsData, setObsData] = useReducer(newDataReducer, null);
  const [lightningData, setLightningData] = useReducer(newDataReducer, null);
  const [showOptions, setOptionsVisible] = useState(false);
  const [showMenu, setMenuVisible] = useState(false);
  const [dataPanelLocation, setDataPanelLocation] = useState(0);
  const [dataPanelSize, setDataPanelSize] = useState(1);
  const [isGraphModelVisible, setisGraphModelVisible] = useState(false);
  const [isAlertModalVisable, setIsAlertModalVisable] = useState(false);
  const [selectedPlate, setSelectedPlate] = useState(null);
  const [stationData, setStationData] = useState(null);
  const [background, setBackground] = useState(
    <StandardBackground weather_type="none" />
  );
  const [selectedInterval, setSelectedInterval] = useState(43200);
  const [videoServer, setVideoServer] = useState(0);
  const searchParams = useParams();
  const location = useLocation();
  const stationID = searchParams.station;
  const stationSnapshot = location?.state?.img || null;

  const [stationHasWeatherData, setStationHasWeatherData] = useState(false);

  const handlePlateClick = (plate) => {
    setisGraphModelVisible(true);
    setSelectedPlate(plate);
  };

  const handleModelClose = () => {
    setisGraphModelVisible(false);
    setIsAlertModalVisable(false);
    setSelectedPlate(null);
  };

  const handleMouseCallback = (enabled) => {
    setOptionsVisible(enabled);
  };

  const handleNamePlateCallback = (enabled) => {
    setMenuVisible(enabled);
  };

  const handleAlertPlateCallback = () => {
    setIsAlertModalVisable(true);
  };

  const handleDataPanelLocationChange = (location) => {
    setDataPanelLocation(location);
  };

  const handleVideoServerChange = (server) => {
    setVideoServer(server);
  };

  const fetchData = async (interval) => {
    try {
      if (!stationDataFetched.current) {
        stationDataFetched.current = true;
        setLoadingText("Gathering Station Information");
        const response = await fetch(
          `https://realtime.hazcams.wxlogic.com/api/station/${stationID}`
        );
        if (!response.ok) throw new Error("Network response was not ok");
        const jsonData = await response.json();
        delete jsonData.history;
        delete jsonData.range;
        setStationData(jsonData);
        document.title = jsonData.name;
      }

      if (!historicalDataFetched.current) {
        historicalDataFetched.current = true;
        setLoadingText("Gathering Historical Data");
        const url = interval
          ? `https://realtime.hazcams.wxlogic.com/api/station/historical/${stationID}/${interval}`
          : `https://realtime.hazcams.wxlogic.com/api/station/historical/${stationID}/${selectedInterval}`;
        const response = await fetch(url);
        if (!response.ok) throw new Error("Network response was not ok");
        const jsonData = await response.json();
        setObsData({ type: REPLACE_DATA, payload: jsonData });
        updateData.current = jsonData;
      }
    } catch (error) {
      setLoadingText("Error, Trying again...");
    }
  };

  const handleUpdate = (e) => {
    const newData = JSON.parse(e.data);
    console.log(newData);
    setStationData((prevStationData) => ({
      ...prevStationData,
      data_timestamp: Date.now(),
    }));

    for (const obs in newData) {
      if (obs === "lightning_distance") {
        setLightningData({
          type: UPDATE_LIGHTNING_DATA,
          payload: { parameter: obs, value: newData[obs] },
        });
      } else {
        setObsData({
          type: UPDATE_VALUE,
          payload: { parameter: obs, value: newData[obs] },
        });
      }
    }
  };

  const connectEventSource = () => {
    setLoadingText("Connecting to Realtime Server...");

    const source = new EventSource(
      `https://realtime.hazcams.wxlogic.com/api/subscribe`
    );

    source.addEventListener("open", () => {
      eventListenerConnected.current = true;
      setLoadingText("Connected to Realtime Server");
    });

    source.addEventListener(`${stationID}`, handleUpdate);

    source.addEventListener("error", (e) => {
      eventListenerConnected.current = false;
      console.error("Error connecting to Realtime Server", e);
      if (reconnectIntervalRef.current) {
        clearInterval(reconnectIntervalRef.current);
      }
      reconnectIntervalRef.current = setInterval(() => {
        if (!eventListenerConnected.current) {
          console.log("Attempting to reconnect to Realtime Server...");
          connectEventSource();
        }
      }, 5000); // Try to reconnect every 5 seconds
    });

    eventSourceRef.current = source;
  };

  useEffect(() => {
    connectEventSource();

    document.addEventListener("visibilitychange", () => {
      if (
        document.visibilityState === "visible" &&
        !eventListenerConnected.current
      ) {
        connectEventSource();
      }
    });

    return () => {
      if (eventSourceRef.current) {
        eventSourceRef.current.close();
      }
      if (reconnectIntervalRef.current) {
        clearInterval(reconnectIntervalRef.current);
      }
      document.removeEventListener("visibilitychange", () => {});
    };
  }, []);

  useEffect(() => {
    // Fetch data initially
    fetchData();

    // Set up a timer to fetch data every minute
    const intervalId = setInterval(() => {
      stationDataFetched.current = false;
      fetchData();
    }, 60000); // 60000 milliseconds = 1 minute

    // Cleanup the timer when the component unmounts
    return () => clearInterval(intervalId);
  }, []); // Empty dependency array to run effect only once

  useEffect(() => {
    if (obsData == null || obsData == undefined) {
      setStationHasWeatherData(false);
    } else if (!obsData.wind_now) {
      //failsafe
      setStationHasWeatherData(false);
    } else {
      if (stationData.data_timestamp > Number(Date.now()) - 1.8e6) {
        setStationHasWeatherData(true);
      } else {
        setStationHasWeatherData(false);
      }
    }
  }, [obsData, stationData]);

  const handleIntervalSelect = (interval) => {
    setSelectedInterval(interval);
    historicalDataFetched.current = false;
    fetchData(interval);
  };

  const handleDataPanelSizeChange = (size) => {
    setDataPanelSize(size);
  };

  if (!stationData || !obsData || !eventListenerConnected.current) {
    return (
      <LoadingPage loadingText={loadingText} loadingImage={stationSnapshot} />
    );
  }

  return (
    <div className="bg-white dark:bg-black">
      <AlertModal
        isOpen={isAlertModalVisable}
        closeModal={handleModelClose}
        stationLocation={stationData.geo}
        stationName={stationData.name}
      />
      <LineGraphModal
        isOpen={isGraphModelVisible}
        closeModal={handleModelClose}
        stationId={stationID}
        stationName={stationData ? stationData.name : "Loading"}
        dataName={selectedPlate}
        dataInterval={selectedInterval}
        intervalSelect={handleIntervalSelect}
      />
      <StationSettingsPlate
        stationData={stationData}
        latencyData={obsData.net_latency}
        enabled={showOptions}
        enabledCallback={handleMouseCallback}>
        <TimeIntervalPicker
          onSelectInterval={handleIntervalSelect}
          currentInterval={selectedInterval}
        />
        <DataPanelLocationPicker
          onSelectLocation={handleDataPanelLocationChange}
        />
        <DataPanelSizePicker onSelectSize={handleDataPanelSizeChange} />
        <VideoServerPicker onSelectServer={handleVideoServerChange} />
      </StationSettingsPlate>

      <div className="text-white text-4xl font-bold">
        <div className="transition-all duration-500 absolute flex flex-wrap fixed top-0 left-2 bg-white bg-opacity-0 z-40 space-x-2 space-y-2">
          <StationNamePlate
            name={stationData ? stationData.name : "Loading..."}
            enabled={showMenu}
            callback={handleNamePlateCallback}
            showSettings={handleMouseCallback}
          />
          <StationAlertPlate
            location={stationData.geo}
            enabled={true}
            stationId={stationID}
            lightning={lightningData}
            clicked={handleAlertPlateCallback}
          />
          <AirQualityPlate
            pm25_aqi={obsData?.pm25_aqi?.value}
            pm10_aqi={obsData?.pm10_aqi?.value}
          />
        </div>

        <div
          className="flex lg:flex-row flex-col w-full h-dvh"
          >
          <div className="w-full lg:h-full aspect-video">
            <HLSPlayer
              videoUrl={stationData.video_servers[videoServer]?.camera_url}
              posterUrl={stationData.video_servers[videoServer]?.snapshot_url}
            />
          </div>
          <div className="flex flex-col w-full lg:w-auto h-full pt-1 pb-1">
            <DataPanel
              stationData={stationData}
              dataEnabled={stationHasWeatherData}
              location={dataPanelLocation}
              grid_size={dataPanelSize}
              config={config}
              sponsor_plate={
                <ImagePlate sponsor={stationData?.sponsor} config={config} />
              }>
              <TemperaturePlate
                value={obsData?.temperature?.value}
                trendline={obsData?.temperature?.trendline}
                range={obsData?.temperature?.range}
                clicked={handlePlateClick}
                config={config}
              />
              <HumidityPlate
                value={obsData?.humidity?.value}
                trendline={obsData?.humidity?.trendline}
                range={obsData?.humidity?.range}
                clicked={handlePlateClick}
                config={config}
              />
              <WindDirectionPlate
                value={obsData?.wind_now?.value}
                direction={obsData?.wind_direction?.value}
                trendline={obsData?.wind_now?.trendline}
                range={obsData?.wind_now?.range}
                clicked={handlePlateClick}
                config={config}
              />
              <WindPlate
                value={obsData?.wind_now?.value}
                direction={obsData?.wind_direction?.value}
                trendline={obsData?.wind_now?.trendline}
                range={obsData?.wind_now?.range}
                clicked={handlePlateClick}
                config={config}
              />
              <DewPointPlate
                value={obsData?.dew_point?.value}
                trendline={obsData?.dew_point?.trendline}
                range={obsData?.dew_point?.range}
                clicked={handlePlateClick}
                config={config}
              />
              <PressurePlate
                value={obsData?.pressure?.value}
                trendline={obsData?.pressure?.trendline}
                range={obsData?.pressure?.range}
                clicked={handlePlateClick}
                config={config}
              />
              <UVPlate
                value={obsData?.uv?.value}
                trendline={obsData?.uv?.trendline}
                range={obsData?.uv?.range}
                clicked={handlePlateClick}
                config={config}
              />
              <SolarRadiationPlate
                value={obsData?.solar_radiation?.value}
                trendline={obsData?.solar_radiation?.trendline}
                range={obsData?.solar_radiation?.range}
                clicked={handlePlateClick}
                config={config}
              />
              <PM25_Plate
                pm25={obsData?.pm25?.value}
                pm25_aqi={obsData?.pm25_aqi?.value}
                range={obsData?.pm25?.range}
                trendline={obsData?.pm25?.trendline}
                clicked={handlePlateClick}
                config={config}
              />
              <PM10_Plate
                pm10={obsData?.pm10?.value}
                pm10_aqi={obsData?.pm10_aqi?.value}
                range={obsData?.pm10?.range}
                trendline={obsData?.pm10?.trendline}
                clicked={handlePlateClick}
                config={config}
              />
              <RainRate
                value={obsData?.rain_min?.value}
                trendline={obsData?.rain_min?.trendline}
                range={obsData?.rain_min?.range}
                config={config}
              />
              <RainDay stationId={stationID} config={config} />
              <WetBulbGlobePlate
                value={obsData?.wet_bulb_globe?.value}
                trendline={obsData?.wet_bulb_globe?.trendline}
                range={obsData?.wet_bulb_globe?.range}
                clicked={handlePlateClick}
                config={config}
              />
              <WindChillPlate
                value={obsData?.wind_chill?.value}
                trendline={obsData?.wind_chill?.trendline}
                range={obsData?.wind_chill?.range}
                clicked={handlePlateClick}
                config={config}
              />
              <ImagePlate sponsor={stationData?.sponsor} config={config} />
              {stationData?.tags?.includes("AIRPORT") ? (
                <AirportPlate
                  windSpeedAverage={obsData?.wind_avg?.value}
                  windDirectionAverage={obsData?.wind_direction_avg?.value}
                  windRange={obsData?.wind_now?.range}
                  IATA={stationData?.data?.IATA}
                  grid_size={dataPanelSize}
                  config={config}
                />
              ) : null}
              <Map
                location={stationData.geo}
                config={config}
                grid_size={dataPanelSize}
                layers={{ radar: true, spc_day_1: false, alerts: true }}
                config={config}
              />
            </DataPanel>
          </div>
        </div>
      </div>
    </div>
  );
}
