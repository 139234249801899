//@ts-nocheck
import React, { useEffect, useState } from "react";
import { interpolateColor, lightenColor, createLinearGradient } from '../utils/colorUtils';

import { WiNightSprinkle } from "react-icons/wi";
import LoadingPlate from "./loading_plate";

const rangeMultiplier = 5;

const colorArray = [
  [
    [-94.27, [115, 70, 105, 255]],
    [-67.27, [202, 172, 195, 255]],
    [-40.27, [162, 70, 145, 255]],
    [-13.27, [143, 89, 169, 255]],
    [4.73, [157, 219, 217, 255]],
    [17.33, [106, 191, 181, 255]],
    [24.53, [100, 166, 189, 255]],
    [32, [93, 133, 198, 255]],
    [33.53, [68, 125, 99, 255]],
    [49.73, [128, 147, 24, 255]],
    [69.53, [243, 183, 4, 255]],
    [85.73, [232, 83, 25, 255]],
    [116.33, [71, 14, 0, 255]],
  ],
];

export default function DewPointPlate({ value, config, trendline,  range,  clicked }) {

  const [minValue, setMinValue] = useState<number | null>(null);
  const [maxValue, setMaxValue] = useState<number | null>(null);
  const [linePath, setLinePath] = useState<string | null>(null);

  //console.log(value);
  //console.log(trendline);

  useEffect(() => {
    if (range && trendline) {
      //console.log(range);
      const min = Math.round(range.min);
      const max = Math.round(range.max);
      setMinValue(min);
      setMaxValue(max);

      const step = 100 / (trendline.length - 1);
      setLinePath(
        trendline
          .map((value, index) => {
            const x = (index * step).toFixed(2);
            const y = (
              100 -
              ((value - (min - rangeMultiplier)) /
                (rangeMultiplier + max - (min - rangeMultiplier))) *
                100
            ).toFixed(2);
            return `${x} ${y}`;
          })
          .join(" ")
      );
    } else {
      setMinValue(null);
      setMaxValue(null);
    }
  }, [trendline, range]);

  if (
    value === null ||
    minValue === null ||
    maxValue === null ||
    linePath === null
  ) {
    return <LoadingPlate/>; // Placeholder or loading indicator
    return null //Used for non forced panels
  }

  

  const color = interpolateColor(Number(value), colorArray);
  const linearGradient = createLinearGradient(
    color,
    Number(value),
    Number(minValue),
    Number(maxValue)
  );

  return (
    <div
      className={`relative ${config.panel_height} grid items-center bg-black bg-opacity-70 rounded-md ${config.panel_padding} ${config.panel_margin}  transition-all duration-1000 hover:brightness-50 hover:drop-shadow-lg cursor-pointer`}
      style={{
        background: `${lightenColor(color, -40)}`,
        position: "relative",
      }}
      onClick={() => clicked('dew_point')}
    >
      <svg
        className="absolute inset-0 rounded-md"
        viewBox="0 0 100 100"
        preserveAspectRatio="none"
        style={{ width: "100%", height: "100%"}}
      >
        <polygon
          points={`0 100, ${linePath}, 100 100`}
          fill={color}
          opacity="1"
        />
      </svg>
      <div className="absolute left-2 flex items-center text-white z-10">
        <WiNightSprinkle size={32}/>
      </div>
      <div className="absolute right-2 text-3xl text-right z-10">{Math.round(value)}</div>
      <div className="text-xs text-right absolute bottom-0 inset-x-0 p-1 z-10">
        °F
      </div>
      <div className="text-xs text-left absolute bottom-0 inset-x-0 p-1 z-10">
        {minValue} | {maxValue}
      </div>
      <div className="text-xs text-left absolute top-0 inset-x-0 p-1 z-10">
        Dew Point
      </div>
    </div>
  );
};

