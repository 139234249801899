//@ts-nocheck

import React, { useEffect, useState } from "react";



const LoadingPlate: React.FC = ({}) => {
  

  return (
    <div
      className="relative h-16 grid items-centerbg-opacity-70 rounded-md p-4 m-2 justify-between transition-all duration-1000 bg-slate-500 animate-pulse"
    >
    </div>
  );
};


export default LoadingPlate;
