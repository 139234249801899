//@ts-nocheck
import React from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

export default function BackButton({ enabled }) {
  const urlParam = new URLSearchParams(window.location.search).get('referer');
  const backUrl = urlParam || '/';

  return (
    <div className={`absolute left-0 top-0 transition-all duration-500 items-center bg-white bg-opacity-0 z-40  mb-2  ${enabled ? '' : 'lg:opacity-0'}`}>
      <div className="bg-black backdrop-blur-lg bg-gray-500 bg-opacity-75 rounded-full text-white font-bold p-4 flex items-center justify-center z-40 h-12 w-12">
        <ArrowBackIcon />
      </div>
    </div>
  );
};



