//@ts-nocheck
import React, { useState, useEffect } from "react";
import BoltIcon from "@mui/icons-material/Bolt";

interface Props {
  location: {
    lat: number;
    lon: number;
    alt: number;
  };
  enabled: boolean;
  stationId: string;
  lightning: object;
  clicked
  
}

interface Alert {
  id: string;
  event: string;
}

const StationAlertPlate: React.FC<Props> = ({
  location,
  enabled,
  stationId,
  lightning,
  clicked
}) => {
  const [alerts, setAlerts] = useState<Alert[]>([]);
  const [currentAlertIndex, setCurrentAlertIndex] = useState(0);
  const [currentAlertColor, setCurrentAlertColor] = useState("bg-gray-500");
  const [currentLightning, setCurrentLightning] = useState(false);
  const [currentLightningDistance, setCurrentLightningDistance] = useState('--');
  const [currentLightningColor, setCurrentLightningColor] = useState("bg-gray-500");
  const [currentLightningTimeInterval, setcurrentLightningTimeInterval] = useState('');

  useEffect(() => {
    if(!enabled){
      return;
    }
    const fetchData = async () => {
      try {
        if (location != null) {
          const response = await fetch(
            //"https://realtime.hazcams.wxlogic.com/alerts"
            `https://api.weather.gov/alerts/active?point=${location.lat},${location.lon}`
          );
          const data = await response.json();
          if (data && data.features) {
            const alertData: Alert[] = data.features.map(
              (feature: any) => feature.properties
            );
            setAlerts(alertData);
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();

    // Refresh alerts every 60 seconds
    const intervalId = setInterval(fetchData, 60000);

    // Cleanup the interval on component unmount
    return () => clearInterval(intervalId);
  }, [location.lat, location.lon, enabled]);

  useEffect(() => {
    // Rotate alerts every 5 seconds
    const rotationIntervalId = setInterval(() => {
      setCurrentAlertIndex((prevIndex) =>
        prevIndex < alerts.length - 1 ? prevIndex + 1 : 0
      );
    }, 5000);

    // Cleanup the rotation interval on component unmount
    return () => clearInterval(rotationIntervalId);
  }, [alerts]);


  /*useEffect(() => {  
    console.log(lightning);
    console.log(lightning.live_strikes?.last_timestamp);
    console.log(Date.now());
    if(lightning && lightning.live_strikes?.last_timestamp + 2700000 > Date.now()){
      
      if(lightning.live_strikes?.min_distance < 1){
        setCurrentLightningDistance(parseFloat(lightning.live_strikes?.min_distance).toFixed(2));
      }
      else{
        setCurrentLightningDistance(parseFloat(lightning.live_strikes?.min_distance).toFixed(0));
      }
      if(Number(lightning.live_strikes?.min_distance) > 20){
        setCurrentLightningColor("bg-gray-500");
        setCurrentLightning(true);
      }
      else if (Number(lightning.live_strikes?.min_distance) <= 20 && Number(lightning.live_strikes?.min_distance) > 10){
        setCurrentLightningColor("bg-yellow-300");
        setCurrentLightning(true);
      }
      else if (Number(lightning.live_strikes?.min_distance) <= 10 && Number(lightning.live_strikes?.min_distance) >= 0){
        setCurrentLightningColor("bg-red-700");
        setCurrentLightning(true);
      }
      else{
        setCurrentLightning(false);
      }
    }  
     
    

  }, [lightning]);
  */

  const currentAlert = alerts[currentAlertIndex];

  // Set the color based on the current alert type
  useEffect(() => {
    if (currentAlert) {
      if (alerts[currentAlertIndex].event.includes("Statement")) {
        setCurrentAlertColor("bg-yellow-500");
      } else if (alerts[currentAlertIndex].event.includes("Watch")) {
        setCurrentAlertColor("bg-orange-500");
      } else if (alerts[currentAlertIndex].event.includes("Warning")) {
        setCurrentAlertColor("bg-red-700");
      } else{
        setCurrentAlertColor("bg-gray-500");
      }
    }
  }, [currentAlert]);

  //${enabled ? "" : "hidden"}

  return (
    <div
      className={`transition-all duration-500 hover:brightness-50 hover:drop-shadow-lg cursor-pointer m-0 ${
        enabled ? "" : "hidden"
      }`
    }
    onClick={() => clicked(true)}
    >
      {currentAlert && (
        <div
          className={`bg-opacity-75 backdrop-blur-lg rounded-full flex items-center p-4 w-fit h-12 text-sm   lg:visible transition-all duration-1000 w-80 text-center truncate  ${currentAlertColor}`}
        >
          {currentAlert.event}
        </div>
      )}

      {false && (<div
        className={
          `bg-opacity-75 backdrop-blur-lg rounded-full p-4 flex items-center text-sm  lg:visible transition-all duration-100 text-center md:mt-0 mt-4 h-12 ${currentLightningColor}`
        }
      >
        <BoltIcon fontSize="large"/> {currentLightningDistance} Miles

        <div className="text-xs text-center absolute bottom-0 inset-x-0 p-1 z-10 w-fill">
          {currentLightningTimeInterval}
        </div>
        <div className="text-xs text-center absolute top-0 inset-x-0 p-0 z-10 w-fill"> 
          
        </div>
      </div>)}
    </div>
  );
};

export default StationAlertPlate;
