//@ts-nocheck

import React from "react";
import BackButton from "./BackButton";


export default function StationNamePlate({ name, enabled, callback, showSettings }) {
  const urlParam = new URLSearchParams(window.location.search).get("referer");
  const backUrl = urlParam || "/";

  const handleBackClick = (e) => {
    e.stopPropagation()
    // Do other stuff here
 }

  return (
    <div
      className={`transition-all duration-1000 flex  items-center justify-end bg-gray-500 bg-opacity-75 backdrop-blur-lg rounded-full p-4 mt-2 w-fit cursor-pointer ${
        enabled ? "pl-14" : "lg:pl-4 pl-14"
      } h-12 z-20 text-sm`}
      onMouseEnter={() => callback(true)}
      onMouseLeave={() => callback(false)}
      onClick={() => showSettings(true)}>
      {name}

      <a href={backUrl} onClick={handleBackClick}>
        <BackButton enabled={enabled} />
      </a>
    </div>
  );
}
