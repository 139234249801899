//@ts-nocheck
import React, { useEffect, useState } from "react";
import { interpolateColor, lightenColor, createLinearGradient } from '../utils/colorUtils';

import { WiDaySunny } from "react-icons/wi";
import LoadingPlate from "./loading_plate";

const rangeMultiplier = 1;

const colorArray = [
  [
    [0, [110, 110, 110, 255]],
    [2, [61, 167, 46, 255]],
    [5, [189, 180, 0, 256]],
    [7, [241, 139, 1, 255]],
    [10, [229, 50, 17, 255]],
    [11, [181, 103, 164, 255]],
    [19, [255, 0, 252, 256]],
  ],
];

export default function UVPlate({ value, config, trendline,  range,  clicked }) {

  const [minValue, setMinValue] = useState<number | null>(null);
  const [maxValue, setMaxValue] = useState<number | null>(null);
  const [linePath, setLinePath] = useState<string | null>(null);

  //console.log(value);
  //console.log(trendline);

  useEffect(() => {
    if (range && trendline) {
      //console.log(range);
      const min = Math.round(range.min);
      const max = Math.round(range.max);
      setMinValue(min);
      setMaxValue(max);

      const step = 100 / (trendline.length - 1);
      setLinePath(
        trendline
          .map((value, index) => {
            const x = (index * step).toFixed(2);
            const y = (
              100 -
              ((value - (min - rangeMultiplier)) /
                (rangeMultiplier + max - (min - rangeMultiplier))) *
                100
            ).toFixed(2);
            return `${x} ${y}`;
          })
          .join(" ")
      );
    } else {
      setMinValue(null);
      setMaxValue(null);
    }
  }, [trendline, range]);

  if (
    value === null ||
    minValue === null ||
    maxValue === null ||
    linePath === null
  ) {
    //return <LoadingPlate/>; // Placeholder or loading indicator
    return null //Used for non forced panels
  }

  

  const color = interpolateColor(Number(value), colorArray);
  const linearGradient = createLinearGradient(
    color,
    Number(value),
    Number(minValue),
    Number(maxValue)
  );

  return (
    <div
      className={`relative ${config.panel_height} grid items-center bg-black bg-opacity-70 rounded-md ${config.panel_padding} ${config.panel_margin}  transition-all duration-1000 hover:brightness-50 hover:drop-shadow-lg cursor-pointer`}
      style={{
        background: `${lightenColor(color, -40)}`,
        position: "relative",
      }}
      onClick={() => clicked('uv')}
    >
      <svg
        className="absolute inset-0 rounded-md"
        viewBox="0 0 100 100"
        preserveAspectRatio="none"
        style={{ width: "100%", height: "100%"}}
      >
        <polygon
          points={`0 100, ${linePath}, 100 100`}
          fill={color}
          opacity="1"
        />
      </svg>
      <div className="absolute left-2 flex items-center text-white z-10">
        <WiDaySunny size={32}/>
      </div>
      <div className="absolute right-2 text-3xl text-right z-10">{Math.round(value)}</div>
      <div className="text-xs text-right absolute bottom-0 inset-x-0 p-1 z-10">
        UV
      </div>
      <div className="text-xs text-left absolute bottom-0 inset-x-0 p-1 z-10">
        {minValue} | {maxValue}
      </div>
      <div className="text-xs text-left absolute top-0 inset-x-0 p-1 z-10">
        UV Index
      </div>
    </div>
  );
};

