

import React, { useRef, useEffect } from 'react';

interface Props {
  weather_type: string;
}

const StandardBackground: React.FC<Props> = ({ weather_type }) => {
  
//TODO SETUP AI GENERATED BACKGROUNDS

  return (
    
        <img className='w-full h-1/2 md:h-full' style={{
          position: "absolute",
          top: 0,
          left: 0,
          display: "flex",
          overflow: "hidden",
        }} src='/bg.jpg'/>
 
  );

};

export default StandardBackground;
