//@ts-nocheck
import React, { useState, useEffect } from "react";
import BoltIcon from "@mui/icons-material/Bolt";

const interpolateColor = (value: number, colorArray: [number, number[]][]) => {
  for (let i = 1; i < colorArray[0].length; i++) {
    const [stop, color] = colorArray[0][i];
    const [r1, g1, b1, a1] = colorArray[0][i - 1][1];
    const [r2, g2, b2, a2] = color;

    if (value <= stop) {
      const weight =
        (value - colorArray[0][i - 1][0]) / (stop - colorArray[0][i - 1][0]);
      const r = Math.round(r1 + weight * (r2 - r1));
      const g = Math.round(g1 + weight * (g2 - g1));
      const b = Math.round(b1 + weight * (b2 - b1));
      const a = Math.round(a1 + weight * (a2 - a1));

      return `rgba(${r}, ${g}, ${b}, ${a / 255})`;
    }
  }

  const [lastStop, lastColor] = colorArray[0][colorArray[0].length - 1];
  const [lastR, lastG, lastB, lastA] = lastColor;

  return `rgba(${lastR}, ${lastG}, ${lastB}, ${lastA / 255})`;
};


const darkenColor = (color: string, percent: number) => {
  const [r, g, b, a] = color
    .substring(color.indexOf("(") + 1, color.indexOf(")"))
    .split(",")
    .map((val) => parseFloat(val.trim()));

  const newR = Math.max(0, r - (percent / 100) * r);
  const newG = Math.max(0, g - (percent / 100) * g);
  const newB = Math.max(0, b - (percent / 100) * b);

  return `rgba(${newR}, ${newG}, ${newB}, ${a})`;
};

export default function AirQualityPlate({ pm25_aqi, pm10_aqi}) {
  const [currentAirQuality, setCurrentAirQuality] = useState(0);
  
  useEffect(()=> {
    if(pm10_aqi>pm25_aqi){
      setCurrentAirQuality(pm10_aqi);
    }
    else{
      setCurrentAirQuality(pm25_aqi);
    }
  },  [pm25_aqi, pm10_aqi]);

  if (
    pm10_aqi === null ||
    pm25_aqi === null
  ) {
    //return <LoadingPlate/>; // Placeholder or loading indicator
    return null //Used for non forced panels
  }

  const colorArray = [
    [
      [0, [71,230,14, 255]],
      [50, [71,230,14, 255]],

      [51, [250,255,41, 255]],
      [100, [250,255,41, 255]],

      [101, [240,133,16, 255]],
      [150, [240,133,16, 255]],

      [151, [255,0,0, 255]],
      [200, [255,0,0, 255]],

      [201, [135,58,192, 255]],
      [300, [135,58,192, 255]],

      [301, [131,33,47, 255]],
      [5000, [131,33,47, 255]],
      
    ],
  ];

  

  return (
    <div  className={`transition-all duration-500  m-0 ${currentAirQuality > 50  ? "" : "hidden"}`}>
        <div
          className={`bg-opacity-75 backdrop-blur-lg rounded-full flex items-center p-4 w-fit h-12 text-sm   lg:visible transition-all duration-1000 w-80 text-center truncate`}
          style={{
            background: `${darkenColor(interpolateColor(Number(currentAirQuality), colorArray), 50)}`,
            position: "relative",
          }}
        >
          AQI {Math.round(currentAirQuality)}
        </div>
      </div>
  );
}
