//@ts-nocheck
import React, { useState, useEffect } from "react";
import { MdPeople, MdAccessTime, MdArrowDropUp   } from "react-icons/md";

const formatDateTime = (timestamp) => {
  if (!timestamp) return '';
  const date = new Date(timestamp);
  return date.toLocaleString(); // You can customize the format here
};

const formatLocalTime = (timezone) => {
  if (!timezone) return '';
  
  const date = new Date();
  const options = {
    timeZone: timezone,
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: '2-digit',
    second: '2-digit',
    hour12: true, // Use 24-hour time
  };
  
  return new Intl.DateTimeFormat('en-US', options).format(date);
};

export default function StationSettingsPlate({
  stationData,
  latencyData,
  children,
  enabled,
  enabledCallback,
}) {
  const [currentLocalTime, setCurrentLocalTime] = useState(() =>
    formatLocalTime(stationData.timezone)
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentLocalTime(formatLocalTime(stationData.timezone));
    }, 1000);

    return () => clearInterval(interval);
  }, [stationData.timezone]);

  const share = () => {
    if (navigator.share) {
      navigator
        .share({
          title: document.title,
          url: window.location.href,
        })
        .then(() => console.log("Successfully shared"))
        .catch((error) =>
          console.error("Something went wrong sharing the URL", error)
        );
    } else {
      console.error("Web Share API is not supported in your browser.");
    }
  };

  return (
    <div
      className={`transition-all duration-500 absolute top-20 left-4 z-30  ${
        enabled ? "" : "hidden"
      }`}>
      <div className="relative bg-black backdrop-blur-lg bg-gray-500 bg-opacity-50 rounded-lg text-white font-bold p-4 w-96">
        <button
          type="button"
          className="absolute top-4 right-4 text-white  dark:text-white bg-gray-700 hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 inline-flex justify-center items-center"
          data-modal-hide="default-modal"
          onClick={() => enabledCallback(false)}>
          <svg
            className="w-3 h-3"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 14 14">
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
            />
          </svg>
          <span className="sr-only">Close modal</span>
        </button>
        <p>Settings</p>
        <div className="w-full h-4"></div>
        <div className="col">
          <div className="row">
            <div className="flex items-center justify-between">
              <span className="mr-2">Current Viewers:</span>
              <div className="flex items-center">
                <span>{stationData.viewers || 1}</span>
                <MdPeople size={16} className="ml-1" />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="flex items-center justify-between">
              <span className="mr-2">Station Latency:</span>
              <div className="flex items-center">
                <span>{latencyData?.value? (latencyData.value + "ms") : ("Not Measured")}</span>
                {latencyData?.value? (<MdAccessTime size={16} className="mx-1" />) : null}
                <span> {latencyData?.range? (latencyData.range.max + "ms"): null}</span>
                {latencyData?.range? (<MdArrowDropUp size={16} className="ml-1" />) : null}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="flex items-center justify-between">
              <span className="mr-2">Local Time:</span>
              <div className="flex items-center">
                <span>{currentLocalTime}</span>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="flex items-center justify-between">
              <span className="mr-2">Station Timestamp: </span>
              <div className="flex items-center">
                <span>{formatDateTime(stationData.timestamp)}</span>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="flex items-center justify-between">
              <span className="mr-2">Data Timestamp: </span>
              <div className="flex items-center">
                <span>{formatDateTime(stationData.data_timestamp)}</span>
              </div>
            </div>
          </div>
        </div>

        <div
          className={`relative bg-gray-600 my-2 transition-all duration-300 grid p-2 text-left w-full grid grid-cols-2  shadow-md rounded-md items-center`}>
          <div className="col">
            <p>Share</p>
            <p className="text-xs">Share this live feed</p>
          </div>
          <div>
            <button
              className="inline-flex w-full justify-center gap-x-1.5 rounded-md dark:bg-gray-500 dark:hover:bg-gray-500 dark:text-white bg-gray-200 hover:bg-gray-50 text-black bg-opacity-75  px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm "
              onClick={share}>
              Share
            </button>
          </div>
        </div>
        {children}
      </div>
    </div>
  );
}
