//@ts-nocheck
import React, { useEffect, useState } from "react";
import AirIcon from "@mui/icons-material/Air";
import LoadingPlate from "./loading_plate";
import { interpolateColor, lightenColor, createLinearGradient } from '../utils/colorUtils';


const rangeMultiplier = 5; //set this for a pretty graph
//const minValue = 0;
//const maxValue = 35;

interface Props {
  value: number | null;
  direction: number | null;
  trendline: number[] | null;
  range: { min: number, max: number } | undefined;
}



const convertToCardinal = (degrees) => {
  const degreeValue = parseFloat(degrees);

  if (!isNaN(degreeValue)) {
    const directions = ["N", "NE", "E", "SE", "S", "SW", "W", "NW"];
    const index = Math.round(degreeValue / 45) % 8;
    return directions[index];
  } else {
    return "";
  }
};

//const createLinearGradient = (color, value, minValue, maxValue) => {
//  const gradientPercent = ((value - minValue) / (maxValue - minValue)) * 100;
//  return `linear-gradient(to right, ${color} ${gradientPercent}%, transparent ${gradientPercent}%)`;
//};

const WindDirectionPlate: React.FC<Props> = ({ value, config, direction, trendline, range, clicked }) => {
  const colorArray = [
    [
      [0, [98, 113, 183, 255]],
      [1, [57, 97, 159, 255]],
      [3, [74, 148, 169, 255]],
      [5, [77, 141, 123, 255]],
      [7, [83, 165, 83, 255]],
      [9, [53, 159, 53, 255]],
      [11, [167, 157, 81, 255]],
      [13, [159, 127, 58, 255]],
      [15, [161, 108, 92, 255]],
      [17, [129, 58, 78, 255]],
      [19, [175, 80, 136, 255]],
      [21, [117, 74, 147, 255]],
      [24, [109, 97, 163, 255]],
      [27, [68, 105, 141, 255]],
      [29, [92, 144, 152, 255]],
      [36, [125, 68, 165, 255]],
      [46, [231, 215, 215, 256]],
      [51, [219, 212, 135, 256]],
      [77, [205, 202, 112, 256]],
      [104, [128, 128, 128, 255]],
    ],
  ];

  const [linePath, setLinePath] = useState<string | null>(null);
  //const [minValue, setMinValue] = useState<number | null>(null);
  //const [maxValue, setMaxValue] = useState<number | null>(null);

  const color = interpolateColor(value, colorArray);
  //const linearGradient = createLinearGradient(color, value, minValue, maxValue);


  useEffect(() => {
    if (range && trendline) {
      const { min, max } = range;
      //setMinValue(min);
      //setMaxValue(max);

      const step = 100 / (trendline.length - 1);
      const path = trendline
        .map((val, index) => {
          const x = (index * step).toFixed(2);
          const y =
            100 -
            ((val - 0) / (rangeMultiplier + max - 0)) * 100; // Adjust this calculation if needed
          return `${x} ${y}`;
        })
        .join(" ");
      setLinePath(path);
    }
  }, [value, range, trendline]);

  if (value === null || trendline === null || range === undefined) {
    
      return <LoadingPlate/>; // Placeholder or loading indicator

    return null //Used for non forced panels
  }


  return (
    
      <div
      className={`relative ${config.panel_height} grid items-center bg-black bg-opacity-70 rounded-md ${config.panel_padding} ${config.panel_margin}  transition-all duration-1000 hover:brightness-50 hover:drop-shadow-lg cursor-pointer`}
        style={{
          background: `${color}`,
        }}
        onClick={() => clicked('wind_direction')}
        
      >
        <div className="absolute bottom-2 left-2 flex  text-white">
          <div
            style={{
              width: "36px",
              height: "36px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <svg
              width="100%"
              height="100%"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 100 100"
              preserveAspectRatio="none"
            >
              <circle
                cx="50"
                cy="50"
                r="45"
                fill="none"
                stroke="white"
                strokeWidth="5"
              />

              <polygon
                points="50,25 65,45 35,45"
                fill="white"
                transform={`rotate(${direction}, 50, 50)`}
              />
            </svg>
          </div>
        </div>
        <div className="absolute right-0 p-2 text-3xl  ps-2 ">
          {convertToCardinal(direction)}
        </div>
        <div className="text-xs text-left absolute top-0 inset-x-0 p-1">
          Wind Direction
        </div>
      </div>

      
  );
};

export default WindDirectionPlate;