//@ts-nocheck
import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import maplibregl from "maplibre-gl";
import StationPlate from "./StationPlate";
import 'maplibre-gl/dist/maplibre-gl.css';

interface Props {
  stations: null;
}

const MapComponent: React.FC<Props> = ({ stations }) => {
  const [map, setMap] = useState<maplibregl.Map | null>(null);

  useEffect(() => {
    const mapInstance = new maplibregl.Map({
      container: "map", // Specify the container ID
      style: "dark-map.json", // Path to your style JSON file
      center: [-95, 40], // Initial longitude and latitude
      zoom: 3, // Initial zoom level
    });

    if (stations) {
      for (let station in stations) {
        var station_timestamp = stations[station].station_timestamp;
        var seconds = Math.floor((Date.now() - station_timestamp) / 1000);
        const marker = document.createElement('div');
        
        if(seconds > 60 || seconds == undefined || seconds == null || Number.isNaN(seconds)){
          marker.innerHTML = '<svg fill="#9e2f2f" width="40px" height="40px" viewBox="0 0 32 32" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg">  <defs>    <style>      .cls-1 {        fill: none;      }    </style>  </defs>  <path d="M17,28V17h1a2.0023,2.0023,0,0,0,2-2V11a2.0023,2.0023,0,0,0-2-2H14a2.0023,2.0023,0,0,0-2,2v4a2.0023,2.0023,0,0,0,2,2h1V28H2v2H30V28ZM14,11h4l.0015,4H14Z" transform="translate(0 0)"/>  <path d="M9.3325,18.2168a7.0007,7.0007,0,0,1,0-10.4341l1.334,1.49a5,5,0,0,0,0,7.4537Z" transform="translate(0 0)"/>  <path d="M22.667,18.2168l-1.334-1.49a4.9995,4.9995,0,0,0,0-7.4537l1.334-1.49a7,7,0,0,1,0,10.4341Z" transform="translate(0 0)"/>  <path d="M6.3994,21.8008a11.0019,11.0019,0,0,1,0-17.6006L7.6,5.8a9.0009,9.0009,0,0,0,0,14.4014Z" transform="translate(0 0)"/>  <path d="M25.6006,21.8008l-1.2012-1.6a9.001,9.001,0,0,0,0-14.4019l1.2012-1.6a11.002,11.002,0,0,1,0,17.6011Z" transform="translate(0 0)"/>  <rect id="_Transparent_Rectangle_" data-name="&lt;Transparent Rectangle&gt;" class="cls-1" width="32" height="32"/></svg>';
        }else{
          marker.innerHTML = '<svg fill="#2f9e33" width="40px" height="40px" viewBox="0 0 32 32" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg">  <defs>    <style>      .cls-1 {        fill: none;      }    </style>  </defs>  <path d="M17,28V17h1a2.0023,2.0023,0,0,0,2-2V11a2.0023,2.0023,0,0,0-2-2H14a2.0023,2.0023,0,0,0-2,2v4a2.0023,2.0023,0,0,0,2,2h1V28H2v2H30V28ZM14,11h4l.0015,4H14Z" transform="translate(0 0)"/>  <path d="M9.3325,18.2168a7.0007,7.0007,0,0,1,0-10.4341l1.334,1.49a5,5,0,0,0,0,7.4537Z" transform="translate(0 0)"/>  <path d="M22.667,18.2168l-1.334-1.49a4.9995,4.9995,0,0,0,0-7.4537l1.334-1.49a7,7,0,0,1,0,10.4341Z" transform="translate(0 0)"/>  <path d="M6.3994,21.8008a11.0019,11.0019,0,0,1,0-17.6006L7.6,5.8a9.0009,9.0009,0,0,0,0,14.4014Z" transform="translate(0 0)"/>  <path d="M25.6006,21.8008l-1.2012-1.6a9.001,9.001,0,0,0,0-14.4019l1.2012-1.6a11.002,11.002,0,0,1,0,17.6011Z" transform="translate(0 0)"/>  <rect id="_Transparent_Rectangle_" data-name="&lt;Transparent Rectangle&gt;" class="cls-1" width="32" height="32"/></svg>';
        }
        
        const map_marker = new maplibregl.Marker({ element: marker })
          .setLngLat([
            stations[station].station_geo.lon,
            stations[station].station_geo.lat,
          ])
          .addTo(mapInstance);

        map_marker.getElement().addEventListener('click', () => {
          window.location.href = "/station/" + stations[station].station_id;
        });

        map_marker.getElement().addEventListener('mouseenter', () => {
          marker.style.cursor = 'pointer';
        });

        map_marker.getElement().addEventListener('mouseleave', () => {
          marker.style.cursor = 'default';
        });
      }
    }

    setMap(mapInstance);

    return () => {
      if (mapInstance) {
        mapInstance.remove();
      }
    };
  }, [stations]);

  return <div id="map" className="w-full h-full rounded-2xl"></div>;
};

export default MapComponent;
