//@ts-nocheck
import React, { useEffect, useState } from "react";

import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';

import LoadingPlate from "./loading_plate";

const rangeMultiplier = 5;
const MB_TO_INHG = 0.02953; // Conversion factor

interface IconTextProps {
  value: string | null;
  trendline: number[] | null;
  range: { min: number; max: number } | undefined;
}

const interpolateColor = (value: number, colorArray: [number, number[]][]) => {
  for (let i = 1; i < colorArray[0].length; i++) {
    const [stop, color] = colorArray[0][i];
    const [r1, g1, b1, a1] = colorArray[0][i - 1][1];
    const [r2, g2, b2, a2] = color;

    if (value <= stop) {
      const weight =
        (value - colorArray[0][i - 1][0]) / (stop - colorArray[0][i - 1][0]);
      const r = Math.round(r1 + weight * (r2 - r1));
      const g = Math.round(g1 + weight * (g2 - g1));
      const b = Math.round(b1 + weight * (b2 - b1));
      const a = Math.round(a1 + weight * (a2 - a1));

      return `rgba(${r}, ${g}, ${b}, ${a / 255})`;
    }
  }

  const [lastStop, lastColor] = colorArray[0][colorArray[0].length - 1];
  const [lastR, lastG, lastB, lastA] = lastColor;

  return `rgba(${lastR}, ${lastG}, ${lastB}, ${lastA / 255})`;
};

const lightenColor = (color: string, percent: number) => {
  const [r, g, b, a] = color
    .substring(color.indexOf("(") + 1, color.indexOf(")"))
    .split(",")
    .map((val) => parseFloat(val.trim()));

  const newR = Math.min(255, r + (percent / 100) * (255 - r));
  const newG = Math.min(255, g + (percent / 100) * (255 - g));
  const newB = Math.min(255, b + (percent / 100) * (255 - b));

  return `rgba(${newR}, ${newG}, ${newB}, ${a})`;
};


const analyzeTrend = (trendline) => {
  // Check if the array is empty or has only one element
  if (trendline.length <= 1) {
      return "Unknown"; // Cannot determine trend with insufficient data
  }

  // Initialize variables to track trend
  let increasing = 0;
  let decreasing = 0;

  // Iterate through the trendline array
  for (let i = 1; i < trendline.length; i++) {
      if (trendline[i] > trendline[i - 1]) {
          increasing++;
      } else if (trendline[i] < trendline[i - 1]) {
          decreasing++;
      }
  }

  // Determine the overall trend based on the counts
  if (increasing > decreasing) {
      return <TrendingUpIcon fontSize="medium"/>;
  } else if (decreasing > increasing) {
      return <TrendingDownIcon fontSize="medium"/>;
  } else {
      return <TrendingFlatIcon fontSize="medium"/>;
  }
}

const createLinearGradient = (
  color: string,
  value: number,
  minValue: number,
  maxValue: number
) => {
  const gradientPercent = ((value - minValue) / (maxValue - minValue)) * 100;
  return `linear-gradient(to right, ${color} ${gradientPercent}%, transparent ${gradientPercent}%)`;
};

const PressurePlate: React.FC<IconTextProps> = ({
  value,
  config,
  trendline,
  range,
  clicked
}) => {
  const [minValue, setMinValue] = useState<number | null>(null);
  const [maxValue, setMaxValue] = useState<number | null>(null);
  const [linePath, setLinePath] = useState<string | null>(null);

  const [trendlineIcon, setTrendlineIcon] = useState(<TrendingFlatIcon fontSize="medium"/>)

  useEffect(() => {
    if (range && trendline) {
      const min = Math.round(range.min);
      const max = Math.round(range.max);
      setMinValue(min);
      setMaxValue(max);
      setTrendlineIcon(analyzeTrend(trendline));



      const step = 100 / (trendline.length - 1);
      setLinePath(
        trendline
          .map((value, index) => {
            const x = (index * step).toFixed(2);
            const y = (
              100 -
              ((value - (min - rangeMultiplier)) /
                (rangeMultiplier + max - (min - rangeMultiplier))) *
                100
            ).toFixed(2);
            return `${x} ${y}`;
          })
          .join(" ")
      );
    } else {
      setMinValue(null);
      setMaxValue(null);
    }
  }, [trendline, range]);

  if (
    value === null ||
    minValue === null ||
    maxValue === null ||
    linePath === null
  ) {
    //return <LoadingPlate/>; // Placeholder or loading indicator
    return null //Used for non forced panels
  }

  const colorArray = [
    [
      [900, [8, 16, 48, 255]],
      [950, [0, 32, 96, 255]],
      [976, [0, 52, 146, 255]],
      [986, [0, 90, 148, 255]],
      [995, [0, 117, 146, 255]],
      [1002, [26, 140, 147, 255]],
      [1007, [103, 162, 155, 255]],
      [1011, [155, 183, 172, 255]],
      [1013, [182, 182, 182, 255]],
      [1015, [176, 174, 152, 255]],
      [1019, [167, 147, 107, 255]],
      [1024, [163, 116, 67, 255]],
      [1030, [159, 81, 44, 255]],
      [1038, [142, 47, 57, 255]],
      [1046, [111, 24, 64, 255]],
      [1080, [48, 8, 24, 255]],
    ],
  ];

  const color = interpolateColor(Number(value), colorArray);
  const linearGradient = createLinearGradient(
    color,
    Number(value),
    Number(minValue),
    Number(maxValue)
  );

  return (
    <div
    className={`relative ${config.panel_height} grid items-center bg-black bg-opacity-70 rounded-md ${config.panel_padding} ${config.panel_margin}  transition-all duration-1000 hover:brightness-50 hover:drop-shadow-lg cursor-pointer`}
    style={{
        background: `${lightenColor(color, -40)}`,
        position: "relative",
      }}
      onClick={() => clicked('pressure')}
    >
      <svg
        className="absolute inset-0 rounded-md"
        viewBox="0 0 100 100"
        preserveAspectRatio="none"
        style={{ width: "100%", height: "100%"}}
      >
        <polygon
          points={`0 100, ${linePath}, 100 100`}
          fill={color}
          opacity="1"
        />
      </svg>
      <div className="absolute left-2 flex items-center text-white z-10">
        {trendlineIcon}
      </div>
      <div className="absolute right-2 text-3xl text-right z-10">{parseFloat(Number(value) * MB_TO_INHG).toFixed(2)}</div>
      <div className="text-xs text-right absolute bottom-0 inset-x-0 p-1 z-10">
        inHg
      </div>
      <div className="text-xs text-left absolute bottom-0 inset-x-0 p-1 z-10">
        {parseFloat(Number(minValue) * MB_TO_INHG).toFixed(2)} | {parseFloat(Number(maxValue) * MB_TO_INHG).toFixed(2)}
      </div>
      <div className="text-xs text-left absolute top-0 inset-x-0 p-1 z-10">
        Pressure
      </div>
    </div>
  );
};

export default PressurePlate;
