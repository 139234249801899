//@ts-nocheck
import React, { useEffect, useState, useMemo, useRef } from "react";

interface AirportProps {
  windSpeedAverage: number | null;
  windDirectionAverage: number | null;
  windRange: { min: number; max: number } | undefined;
  IATA: string | null;
  grid_size: number | 2;
  config: any;
}

const AirportPlate: React.FC<AirportProps> = ({
  windSpeedAverage,
  windDirectionAverage,
  windRange,
  IATA,
  grid_size,
  config
}) => {
  const canvasRef = useRef(null);
  const [dots, setDots] = useState<
    { x: number; y: number; speed: number; direction: number }[]
  >([]);
  const [updaterStarted, setUpdatedStarted] = useState(false);
  const [metar, setMetar] = useState("NO METAR");
  const latestMETAR = useRef<string | null>(null);
  const latestGust = useRef<number | null>(null);



  const size = ['col-span-1', 'col-span-2', 'col-span-3'];

  

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `https://realtime.hazcams.wxlogic.com/api/metar/${IATA}`
        );

        const data = await response.text();

        latestMETAR.current = data;
        // Parse the XML data to extract METAR information
      } catch (error) {
        console.error("Error fetching METAR data:", error);
        latestMETAR.current = "Failed to get METAR";
        setTimeout(fetchData, 30000);
      }
    };

    if (IATA) {
      fetchData();
    }
  }, [IATA]);

  useEffect(() => {
    if (
      latestMETAR.current == null ||
      windSpeedAverage == null||
      windDirectionAverage == null
    ) {
      //console.log("Cannot Update Metar");
      
      return;
      
    }

    //console.log("METAR Update");

    function replaceBeforeKT(inputString, replacement) {
      const indexKT = inputString.indexOf("KT");
      const spaceIndex = inputString.lastIndexOf(" ", indexKT);
      if (indexKT !== -1 && spaceIndex !== -1) {
        return (
          inputString.substring(0, spaceIndex + 1) +
          replacement +
          inputString.substring(indexKT)
        );
      }
      return inputString;
    }

    function replaceTime(inputString, replacement) {
      const indexKT = inputString.indexOf("Z");
      const spaceIndex = inputString.lastIndexOf(" ", indexKT);
      if (indexKT !== -1 && spaceIndex !== -1) {
        return (
          inputString.substring(0, spaceIndex + 1) +
          replacement +
          inputString.substring(indexKT)
        );
      }
      return inputString;
    }

    function getCurrentZuluTime() {
      const now = new Date();
      const day = now.getUTCDate().toString().padStart(2, "0");
      const hour = now.getUTCHours().toString().padStart(2, "0");
      const minute = now.getUTCMinutes().toString().padStart(2, "0");
      return `${day}${hour}${minute}`;
    }

    let windData =
      Math.round(windDirectionAverage).toString().padStart(3, "0") +
      Math.round(windSpeedAverage / 1.15078)
        .toString()
        .padStart(2, "0");

    if (latestGust.current / 1.15078 > 10) {
      windData +=
        "G" +
        Math.round(latestGust.current / 1.15078)
          .toString()
          .padStart(2, "0");
    }

    const currentZuluTime = getCurrentZuluTime();
    let updatedMETAR = replaceBeforeKT(
      latestMETAR.current,
      windData,
      currentZuluTime
    );
    updatedMETAR = replaceTime(updatedMETAR, getCurrentZuluTime());
    setMetar(updatedMETAR);
  }, [latestMETAR, windSpeedAverage, windDirectionAverage, windRange]);

  useEffect(() => {
    //console.log(windSpeedAverage + "," + windDirectionAverage + "," + windRange);
    if (windSpeedAverage != null && windDirectionAverage != null && windRange != null) {      
      const { min, max } = windRange;
      windDirectionAverage = windDirectionAverage;
      windSpeedAverage = windSpeedAverage;
      latestGust.current = max;
    }
  }, [windSpeedAverage, windDirectionAverage, windRange]);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");

    if (!ctx) {
      console.error("Canvas context not supported");
      return;
    }

    if (!windDirectionAverage) {
      return;
    }

    const createDot = () => ({
      x: Math.random() * canvas.width * 4 - canvas.width * 2,
      y: Math.random() * canvas.height * 4 - canvas.height * 2,
      speed: windSpeedAverage.current / 15, // Adjust speed as needed
      direction: windDirectionAverage.current + 90, // Random initial direction in degrees
      timestamp: Date.now(), // Reset the timestamp
      history: [], // Reset dot's position history
    });

    const update = () => {
      // Clear canvas
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      if (windSpeedAverage.current == 0) {
        requestAnimationFrame(update);
        return;
      }

      // Create dots if needed
      while (dots.length < 750) {
        dots.push(createDot());
      }

      const currentTime = Date.now();

      dots.forEach((dot, index) => {
     

        

        //console.log(windDirectionAverage.current ?? 0);

        dot.direction = windDirectionAverage + 90 ?? 0;
        dot.speed = (windSpeedAverage ?? 1) / 25;

        const radians = (dot.direction * Math.PI) / 180;

        // Move dot
        dot.x += Math.cos(radians) * dot.speed;
        dot.y += Math.sin(radians) * dot.speed;

        // Check if dot is off canvas, if so, move it to the opposite side
        if (
          dot.x < -canvas.width * 2 ||
          dot.x > canvas.width * 3 ||
          dot.y < -canvas.height * 2 ||
          dot.y > canvas.height * 3
        ) {
          // Generate random respawn coordinates within the canvas bounds
          dot.x = Math.random() * canvas.width * 4 - canvas.width * 2;
          dot.y = Math.random() * canvas.height * 4 - canvas.height * 2;
          dot.timestamp = currentTime; // Reset the timestamp
          dot.history = []; // Reset dot's position history
        }

        // Add current position to dot's history
        dot.history.push({ x: dot.x, y: dot.y });

        dot.history.forEach((position, i) => {
          if (i < dot.history.length - 50) return; // Only draw the last 50 positions
          const trailAlpha = 0.5 - (i - (dot.history.length - 50)) * 0.01; // Adjusted alpha decay rate
          ctx.beginPath();
          ctx.arc(
            position.x,
            position.y,
            (i - (dot.history.length - 50)) * 0.01,
            0,
            Math.PI * 2
          );
          ctx.fillStyle = `rgba(150, 150, 150,1)`; // Adjust alpha dynamically
          ctx.fill();
        });

        // Limit the history to the last 50 positions
        dot.history = dot.history.slice(-50);

        // Draw dot
        //ctx.beginPath();
        //ctx.arc(dot.x, dot.y, 1, 0, Math.PI * 2);
        //ctx.fillStyle = "rgb(55, 65, 81)";
        //ctx.fillStyle = interpolateColor(latestwindSpeedAverage.current, colorArray);
        //ctx.fill();
      });

      requestAnimationFrame(update);
    };

    setDots(dots);

    if (!updaterStarted) {
      update();
      setUpdatedStarted(true);
    }
  }, [windSpeedAverage, windDirectionAverage, dots]);

  return (
    <div className={`relative h-64 grid ${size[grid_size]} items-center bg-black bg-opacity-80 rounded-md  ${config.panel_padding} ${config.panel_margin}  transition-all duration-1000 bg-gray-100 dark:bg-gray-800`}>
      <div className="text-xs text-left w-full rounded-md text-balance absolute top-0 inset-x-0 p-1 z-10 text-black dark:text-white z-30">
        {metar}
      </div>
      <img
        className="absolute inset-0 rounded-md w-full h-full pt-12 p-2 z-10"
        src={`../airports/${IATA}.svg`} // Fixed src attribute
        alt="Airport"
      />

      <canvas
        id="tsparticles"
        className="absolute w-full h-full overflow-hidden rounded-md z-20"
        ref={canvasRef}
      />

      <div className="text-xs text-right absolute bottom-0 inset-x-0 p-1 z-10 text-black dark:text-white z-30">
      AUTO | {IATA}
      </div>
    </div>
  );
};

export default AirportPlate;
