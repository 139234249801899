//@ts-nocheck
import { Fragment, useState, useEffect } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { ChevronRightIcon } from '@heroicons/react/20/solid';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const server = [
  { label: 'NODE 1', value: 0 },
  { label: 'NODE 2', value: 1 },
];

export default function VideoServerPicker({ onSelectServer }) {
  const [selectedInterval, setSelectedServer] = useState(null);

  const handleServerSelect = (interval) => {
    setSelectedServer(interval);
    onSelectServer(interval.value);
  };

  

  return (
    <Menu
      as="div"
      className={`relative bg-gray-600 my-2 transition-all duration-300 grid p-2 text-left w-full grid grid-cols-2  shadow-md rounded-md items-center`}
    >
      <div className='col'>
      <p>Video Server</p>
      <p className='text-xs'>Choose which video node to stream video from, this can be helpful if there are issues with a certain node</p>
      </div>
      <div>
        <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md dark:bg-gray-500 dark:hover:bg-gray-500 dark:text-white bg-gray-200 hover:bg-gray-50 text-black bg-opacity-75  px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm  ">
          {selectedInterval ? selectedInterval.label : 'NODE 1 (default)'}
          <ChevronRightIcon className="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute z-10 left-24 mt-2 w-56  rounded-md bg-white dark:bg-gray-600 dark:text-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            {server.map((interval, index) => (
              <Menu.Item key={index}>
                {({ active }) => (
                  <a
                    href="#"
                    onClick={() => handleServerSelect(interval)}
                    className={classNames(
                      active ? 'bg-gray-100 text-gray-900 dark:bg-gray-400 dark:text-white' : 'text-gray-700 dark:text-white',
                      'block px-4 py-2 text-sm'
                    )}
                  >
                    {interval.label}
                  </a>
                )}
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
