//@ts-nocheck
import React, { useEffect, useState, useRef, useReducer } from "react";
import { Link, useLocation, useParams } from "react-router-dom";

import HLSBackground from "../VideoPlayers/HLSBackground";

export default function StormChaserPage(props) {
  const searchParams = useParams();
  const chaserID = searchParams.chaser;

  const [currentChaser, setCurrentChaser] = useState();

  const fetchData = async (chaser) => {
    try {
      const response = await fetch(
        `https://realtime.hazcams.wxlogic.com/api/chasers/get_chaser/${chaser}`
      );
      if (!response.ok) {
        setCurrentChaser(null);
        throw new Error("Network response was not ok");
      }
      const jsonData = await response.json();
      console.log(jsonData);
      setCurrentChaser(jsonData);
    } catch {
      setCurrentChaser(null);
    }
  };

  useEffect(() => {
    fetchData(chaserID);

    setInterval(() => {
        fetchData(chaserID);
    }, 30000);
  }, []);

  return (
    <div className="w-screen h-screen  bg-white dark:bg-black ">
      {currentChaser ? (
        <HLSBackground
          videoUrl={currentChaser.properties.videoURL}
          posterUrl={"./storm_chaser.jpg"}
        />
      ) : null}
      <div className="lg:absolute lg:top-2 lg:left-2 lg:right-2 z-50 text-white flex flex-row items-center justify-between p-6 ">
        <div className=" flex flex-col">
          <div className="text-lg font-black">
            {currentChaser?.properties.location}
          </div>
          <div className="text-md font-black">
            {currentChaser?.properties.title}
          </div>
        </div>
        <div className="w-48">
            <a href="https://livestormchasing.com">
            <img src="https://livestormchasing.com/img/logo.5eab52d2.png"/>
            </a>
        </div>
      </div>
    </div>
  );
}
