import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import Home from "./components/Home";
import Station from "./components/StationPage/page";
import StormChaserPage from "./components/StormChaser/StormChaserViewer";


function App(){
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home/>}/>
        <Route path="/station/:station" element={<Station/>}/>
        <Route path="/chaser/:chaser" element={<StormChaserPage/>}/>
      </Routes>
    </Router>
  )
}

export default App;