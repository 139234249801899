//@ts-nocheck
export const interpolateColor = (value: number, colorArray: [number, number[]][]) => {
    for (let i = 1; i < colorArray[0].length; i++) {
      const [stop, color] = colorArray[0][i];
      const [r1, g1, b1, a1] = colorArray[0][i - 1][1];
      const [r2, g2, b2, a2] = color;
  
      if (value <= stop) {
        const weight =
          (value - colorArray[0][i - 1][0]) / (stop - colorArray[0][i - 1][0]);
        const r = Math.round(r1 + weight * (r2 - r1));
        const g = Math.round(g1 + weight * (g2 - g1));
        const b = Math.round(b1 + weight * (b2 - b1));
        const a = Math.round(a1 + weight * (a2 - a1));
  
        return `rgba(${r}, ${g}, ${b}, ${a / 255})`;
      }
    }
  
    const [lastStop, lastColor] = colorArray[0][colorArray[0].length - 1];
    const [lastR, lastG, lastB, lastA] = lastColor;
  
    return `rgba(${lastR}, ${lastG}, ${lastB}, ${lastA / 255})`;
  };
  
  export const lightenColor = (color: string, percent: number) => {
    const [r, g, b, a] = color
      .substring(color.indexOf("(") + 1, color.indexOf(")"))
      .split(",")
      .map((val) => parseFloat(val.trim()));
  
    const newR = Math.min(255, r + (percent / 100) * (255 - r));
    const newG = Math.min(255, g + (percent / 100) * (255 - g));
    const newB = Math.min(255, b + (percent / 100) * (255 - b));
  
    return `rgba(${newR}, ${newG}, ${newB}, ${a})`;
  };
  
  export const createLinearGradient = (
    color: string,
    value: number,
    minValue: number,
    maxValue: number,
  ) => {
    const gradientPercent = ((value - minValue) / (maxValue - minValue)) * 100;
    return `linear-gradient(to right, ${color} ${gradientPercent}%, transparent ${gradientPercent}%)`;
  };

  export const darkenColor = (color: string, percent: number) => {
    const [r, g, b, a] = color
      .substring(color.indexOf("(") + 1, color.indexOf(")"))
      .split(",")
      .map((val) => parseFloat(val.trim()));
  
    const newR = Math.max(0, r - (percent / 100) * r);
    const newG = Math.max(0, g - (percent / 100) * g);
    const newB = Math.max(0, b - (percent / 100) * b);
  
    return `rgba(${newR}, ${newG}, ${newB}, ${a})`;
  };
  