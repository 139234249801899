//@ts-nocheck

import React, { useState, useEffect } from "react";
import axios from "axios";
import StationPlate from "./StationPlate";
import MapComponent from "./map";
import StationDashboard from "./StationPage/page";

function Home() {
  const [stations, setStations] = useState([]);
  const [error, setError] = useState(null);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        "https://realtime.hazcams.wxlogic.com/api/stations?id=" + Date.now()
      );
      setStations(response.data);
    } catch (error) {
      setError(error);
    }
  };

  useEffect(() => {
    document.title = "Hazcams";
    fetchData();
    const interval = setInterval(fetchData, 60000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="bg-white dark:bg-slate-800 md:h-svh md:overflow-hidden">
      <nav className="bg-white dark:bg-slate-800">
        <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
          <a href="https://wxlogic.com/" className="flex items-center">
            <img src="/weather.svg" className="h-8 mr-3" alt="Hazcams Logo" />
            <span className="self-center text-2xl font-semibold whitespace-nowrap dark:text-white">
              Hazcams
            </span>
          </a>
          <button
            data-collapse-toggle="navbar-default"
            type="button"
            className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
            aria-controls="navbar-default"
            aria-expanded="false">
            <span className="sr-only">Open main menu</span>
            <svg
              className="w-5 h-5"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 17 14">
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M1 1h15M1 7h15M1 13h15"
              />
            </svg>
          </button>
          <div
            className="hidden w-full md:block md:w-auto"
            id="navbar-default"></div>
        </div>
      </nav>
      <div className="flex flex-col sm:flex-row h-full">
        <div className="flex w-full sm:w-1/2 sm:h-[90vh] h-96 p-1 md:pl-24 justify-center">
          <MapComponent stations={stations} />
        </div>
        <div className="flex flex-wrap w-full sm:w-1/2 justify-center md:h-screen md:pb-24 overflow-auto">
          {stations.map((station) => (
            <StationPlate
              key={station.station_id}
              id={station.station_id}
              name={station.station_name}
              sponsor={station.station_sponsor}
              description={station.station_description}
              camera={station.station_camera}
              viewers={station.viewers}
              snapshot={station.station_snapshot}
              timestamp={station.station_timestamp}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default Home;
