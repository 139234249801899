//@ts-nocheck
import { Fragment, useState, useEffect } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { ChevronRightIcon } from '@heroicons/react/20/solid';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const locations = [
  { label: 'Disabled', value: -1 },
  { label: 'Enabled (Default)', value: 0 }
];

export default function DataPanelLocationPicker({ onSelectLocation }) {
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [isLGScreen, setIsLGScreen] = useState(false);

  useEffect(() => {
    function handleResize() {
      setIsLGScreen(window.innerWidth >= 1024); // Assuming LG screen width is 1024px or more
    }

    window.addEventListener('resize', handleResize);
    handleResize(); // Initial check

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleLocationSelect = (location) => {
    setSelectedLocation(location);
    onSelectLocation(location.value);
  };

  return (
    <>
      {isLGScreen && (
        <Menu
          as="div"
          className={`relative bg-gray-600 my-2 transition-all duration-300 grid p-2 text-left w-full grid grid-cols-2  shadow-md rounded-md items-center `}
        >
          <div className='col'>
            <p>Data Panel Enabled</p>
            <p className='text-xs'>Select whether or not to show data panels on the right side</p>
          </div>
          <div>
            <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md brightness-50 lg:brightness-100  dark:bg-gray-500 dark:hover:bg-gray-500 dark:text-white bg-gray-200 hover:bg-gray-50 text-black bg-opacity-75  px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm  ">
              {selectedLocation ? selectedLocation.label : 'Enabled (Default)'}
              <ChevronRightIcon className="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
            </Menu.Button>
          </div>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute z-10 left-24 mt-2 w-56  rounded-md bg-white dark:bg-gray-600 dark:text-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div className="py-1">
                {locations.map((location, index) => (
                  <Menu.Item key={index}>
                    {({ active }) => (
                      <a
                        href="#"
                        onClick={() => handleLocationSelect(location)}
                        className={classNames(
                          active ? 'bg-gray-100 text-gray-900 dark:bg-gray-400 dark:text-white' : 'text-gray-700 dark:text-white',
                          'block px-4 py-2 text-sm'
                        )}
                      >
                        {location.label}
                      </a>
                    )}
                  </Menu.Item>
                ))}
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      )}
    </>
  );
}

