//@ts-nocheck
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { MdPeople } from "react-icons/md";

interface Props {
  name: string;
  description: string;
  camera: string;
  viewers: string;
  snapshot: string;
  id: string;
  timestamp: string;
}

const StationPlate: React.FC<Props> = ({
  name,
  description,
  camera,
  viewers,
  sponsor,
  snapshot,
  id,
  timestamp,
}) => {
  if (Date.now() - timestamp > 24 * 60 * 10 || timestamp === undefined) {
    return null;
  }

  return (
    <Link
      to={`/station/${id}`}
      state={{ img: snapshot }}
      className="relative w-full sm:w-72 h-56 border rounded-lg m-1 -p-1 overflow-hidden hover:brightness-150 transition-all duration-700">
      <object
        className="absolute top-0 left-0 w-full h-full object-cover"
        data={snapshot}
        type="image/png">
        <img
          className="absolute top-0 left-0 w-full h-full object-cover"
          src="/fallback_thumbnail.jpg"
          alt="Station snapshot"
        />
      </object>
      <div className="w-full h-full bg-gradient-to-b from-gray-800 to-transparent text-white  backdrop-blur-none p-1">
        <div className="relative z-10 flex flex-col p-2">
          <div className="text-xl text-left pr-14">{name}</div>
          <div className="text-xs text-left pr-14 overflow-hidden line-clamp-2">
            {description}
          </div>
        </div>
        <div className="absolute top-2 right-2 bg-red-800 py-1 px-3 rounded-full text-white text-xs inline-flex items-center space-x-1">
          {viewers} <MdPeople size={16} className="ml-1"/>
        </div>

        <div
          className="absolute bottom-1 right-1 flex w-24 h-12 p-2 items-center bg-opacity-70 rounded-md m-2 justify-between"
          style={{ backgroundColor: sponsor.color }}>
          <img
            className="w-full h-full object-contain"
            src={sponsor.logo + "?id=" + Date.now()}
            alt="Sponsor Logo"
          />
        </div>
      </div>
    </Link>
  );
};

export default StationPlate;
