//@ts-nocheck
import React, { useState, useEffect, useRef } from "react";
import Chart from "react-apexcharts";
import "./apexCharts.css";

const LineGraphModal = ({
  isOpen,
  closeModal,
  stationId,
  stationName,
  dataName,
  dataInterval,
}) => {
  const dataLookup = {
    temperature: {
      name: "Temperature",
      unit: "°F",
      round: 0,
      chartType: "rangeArea",
    },
    wind_now: {
      name: "Wind Speed",
      unit: "MPH",
      round: 0,
      chartType: "rangeArea",
    },
    wind_direction: {
      name: "Wind Direction",
      unit: "°",
      round: 0,
      chartType: "scatter",
    },
    humidity: {
      name: "Humidity",
      unit: "%",
      round: 0,
      chartType: "rangeArea",
    },
    dew_point: {
      name: "Dew Point",
      unit: "°F",
      round: 0,
      chartType: "rangeArea",
    },
    pressure: {
      name: "Pressure",
      unit: "MB",
      round: 1,
      chartType: "rangeArea",
    },
    uv: {
      name: "UV Index",
      unit: "UV",
      round: 0,
      chartType: "rangeArea",
    },
    solar_radiation: {
      name: "Solar Radiation",
      unit: "W/m²",
      round: 0,
      chartType: "rangeArea",
    },
    wet_bulb_globe: {
      name: "Wet Bulb Globe Temperature",
      unit: "°F",
      round: 0,
      chartType: "rangeArea",
    },
    wind_chill: {
      name: " Wind Chill",
      unit: "°F",
      round: 0,
      chartType: "rangeArea",
    },
    pm25: {
      name: "PM 2.5",
      unit: "µg/m3",
      round: 0,
      chartType: "rangeArea"      
    },
    pm10: {
      name: "PM 10",
      unit: "µg/m3",
      round: 0,
      chartType: "rangeArea"      
    }
  };

  const [dataInformation, setDataInformation] = useState(null);
  const [data, setData] = useState(null);
  const [chartData, setChartData] = useState(null);
  const [activeDataInterval, setactiveDataInterval] = useState({
    start: Date.now() - dataInterval * 1000,
    end: Date.now(),
  });

  const modalRef = useRef(null);

  const handleClickOutside = (event) => {
    console.log(event);
    if (modalRef.current && event.target.id == "default-modal") {
      close();
    }
  };

  function getMinMaxTooltip(seriesData, dataPointIndex, dataInformation) {
    var timestamp = new Date(
      seriesData.mean[dataPointIndex].x
    ).toLocaleString();
    var mean = parseFloat(seriesData.mean[dataPointIndex].y).toFixed(
      dataInformation.round
    );
    var min = parseFloat(seriesData.range[dataPointIndex].y[0]).toFixed(
      dataInformation.round
    );
    var max = parseFloat(seriesData.range[dataPointIndex].y[1]).toFixed(
      dataInformation.round
    );

    return `
    <div class="bg-gray-100 dark:bg-gray-600 outline-0 rounded-lg">
    <div class="p-2 w-full bg-gray-200 dark:bg-gray-700 ">
        <div class="text-gray-600 dark:text-white text-center w-full">${timestamp}</div>
    </div>
    <div class="p-1 w-full flex relative">
        <div class="relative p-3 m-1 text-gray-600 dark:text-white bg-gray-200 dark:bg-gray-700 rounded-lg">
            ${min} ${dataInformation.unit}
            <div class="absolute right-2 bottom-1 text-[8px] text-right z-10">MIN</div>
        </div>
        <div class="relative flex p-3 m-1 text-gray-600 dark:text-white bg-gray-200 dark:bg-gray-700 rounded-lg">
          ${mean} ${dataInformation.unit}
          <div class="absolute right-2 bottom-1 text-[8px] text-right z-10">AVG</div></div>
        <div class="relative flex p-3 m-1 text-gray-600 dark:text-white bg-gray-200 dark:bg-gray-700 rounded-lg">
        ${max} ${dataInformation.unit}
          <div class="absolute right-2 bottom-1 text-[8px] text-right z-10">MAX</div></div>
    </div>
    <div class="relative flex p-3 mx-2 mb-2  text-gray-600 dark:text-white bg-gray-200 dark:bg-gray-700 rounded-lg">
    ${seriesData.interval}
          <div class="absolute right-2 bottom-1 text-[8px] text-right z-10">Interval</div></div>
</div>

    `;
  }

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }
  }, [isOpen]);

  function close() {
    setChartData(null);
    setData(null);
    setDataInformation(null);
    closeModal();
  }

  // Update dataInformation when dataName changes
  useEffect(() => {
    if (dataName !== undefined && isOpen) {
      if (dataName && dataLookup[dataName]) {
        setDataInformation(dataLookup[dataName]);
      }

      const fetchData = async () => {
        try {
          const response = await fetch(
            //"https://realtime.hazcams.wxlogic.com/alerts"
            `https://realtime.hazcams.wxlogic.com/api/station/data/${stationId}/${dataName}/${activeDataInterval.start}/${activeDataInterval.end}`
            //`https://api.weather.gov/alerts/active?point=${location.lat},${location.lon}`
          );
          const data = await response.json();
          if (data) {
            setData(data);
            console.log(data);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };

      fetchData();
    }
  }, [dataName, activeDataInterval]);

  useEffect(() => {
    setactiveDataInterval({
      start: Date.now() - dataInterval * 1000,
      end: Date.now(),
    });
  }, [dataInterval]);

  useEffect(() => {
    if (dataInformation !== null && data !== null) {
      const minValue = Math.min(...data.range.map((item) => item.y[0]));
      const maxValue = Math.max(...data.range.map((item) => item.y[1]));

      console.log(dataInformation);
      if (dataInformation.chartType == "rangeArea") {
        setChartData({
          type: dataInformation.chartType,
          series: [
            {
              type: "line",
              name: dataInformation.name,
              data: data.mean,
            },
            {
              type: "rangeArea",
              name: dataInformation.name,
              data: data.range,
            },
          ],
          options: {
            chart: {
              id: "data-chart",
              height: 450,
              events: {
                zoomed: function (chartContext, { xaxis, yaxis }) {
                  // Call your function here
                  if (xaxis) {
                    setactiveDataInterval({ start: xaxis.min, end: xaxis.max });
                  }
                },
                beforeResetZoom: function (chartContext, opts) {
                  return {
                    xaxis: {
                      min: Date.now() - dataInterval * 1000,
                      max: Date.now(),
                    },
                  };
                },
              },
              animations: {
                enabled: false,
                easing: "easeinout",
                speed: 800,
                animateGradually: {
                  enabled: true,
                  delay: 150,
                },
                dynamicAnimation: {
                  enabled: true,
                  speed: 350,
                },
              },
            },
            stroke: {
              width: [5, 0],
              curve: "smooth",
            },
            fill: {
              opacity: [1, 0.2],
            },
            markers: {
              size: [0, 0],
              hover: {
                // Disable markers on hover for the second series
                size: [5, 15],
              },
            },
            colors: ["#2563EB", "#2563EB"],
            xaxis: {
              type: "datetime",
              tooltip: {
                enabled: false,
              },
              labels: {
                datetimeFormatter: {
                  year: "yyyy",
                  month: "MMM 'yy",
                  day: "dd MMM",
                  hour: "HH:mm",
                },
                style: {
                  cssClass:
                    "text-xs font-normal fill-gray-500 dark:fill-gray-400",
                },
              },
            },
            yaxis: {
              title: {
                text: dataInformation.name,
                style: {
                  cssClass:
                    "text-xs font-normal fill-gray-500 dark:fill-gray-400",
                },
              },
              labels: {
                formatter: function (value) {
                  return (
                    parseFloat(value).toFixed(dataInformation.round) +
                    dataInformation.unit
                  );
                },
                style: {
                  cssClass:
                    "text-xs font-normal fill-gray-500 dark:fill-gray-400",
                },
              },
              min: minValue - minValue * 0.05, // Set the minimum value
              max: maxValue + maxValue * 0.05, // Set the maximum value
            },
            legend: {
              show: false,
            },
            dataLabels: {
              enabled: false,
            },
            tooltip: {
              custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                // Check if the current series is the first one
                if (seriesIndex === 0) {
                  // Get tooltip data for the first series
                  var tooltipData = getMinMaxTooltip(
                    data,
                    dataPointIndex,
                    dataInformation
                  );
                  // Return the tooltip HTML
                  return tooltipData;
                } else {
                  // Return null if it's not the first series
                  return null;
                }
              },
            },
          },
        });
      } else if (dataInformation.chartType == "scatter") {
        setChartData({
          type: dataInformation.chartType,
          series: [
            {
              type: "line",
              name: dataInformation.name,
              data: data.mean,
            },
          ],
          options: {
            chart: {
              id: "data-chart",
              height: 350,

              events: {
                zoomed: function (chartContext, { xaxis, yaxis }) {
                  // Call your function here
                  if (xaxis) {
                    setactiveDataInterval({ start: xaxis.min, end: xaxis.max });
                  }
                },
                beforeResetZoom: function (chartContext, opts) {
                  return {
                    xaxis: {
                      min: Date.now() - dataInterval * 1000,
                      max: Date.now(),
                    },
                  };
                },
              },
              animations: {
                enabled: true,
                easing: "linear",
              },
            },
            stroke: {
              width: [5, 0],
              curve: "smooth",
            },

            colors: ["#2563EB"],
            xaxis: {
              type: "datetime",
              tooltip: {
                enabled: false,
              },
              labels: {
                datetimeFormatter: {
                  year: "yyyy",
                  month: "MMM 'yy",
                  day: "dd MMM",
                  hour: "HH:mm",
                },
                style: {
                  cssClass:
                    "text-xs font-normal fill-gray-500 dark:fill-gray-400",
                },
              },
            },
            yaxis: {
              title: {
                text: dataInformation.name,
                style: {
                  cssClass:
                    "text-xs font-normal fill-gray-500 dark:fill-gray-400",
                },
              },
              labels: {
                formatter: function (value) {
                  return (
                    parseFloat(value).toFixed(dataInformation.round) +
                    dataInformation.unit
                  );
                },
                style: {
                  cssClass:
                    "text-xs font-normal fill-gray-500 dark:fill-gray-400",
                },
              },
              forceNiceScale: true,
            },
            legend: {
              show: false,
            },
            dataLabels: {
              enabled: false,
            },
            tooltip: {
              custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                // Check if the current series is the first one
                if (seriesIndex === 0) {
                  // Get tooltip data for the first series
                  var tooltipData = getMinMaxTooltip(
                    data,
                    dataPointIndex,
                    dataInformation
                  );
                  // Return the tooltip HTML
                  return tooltipData;
                } else {
                  // Return null if it's not the first series
                  return null;
                }
              },
            },
          },
        });
      }
    }
  }, [data, dataInterval]);

  if (!isOpen) {
    return null;
  }

  if (!dataInformation || !chartData) {
    //  if(true){
    return (
      <div
        id="default-modal"
        tabIndex="-1"
        aria-hidden="true"
        className="overflow-y-auto overflow-x-hidden fixed inset-0 z-50 flex justify-center p-6 items-center bg-black bg-opacity-50 backdrop-blur-lg ">
        <div className="relative bg-white dark:bg-gray-800 rounded-lg shadow-md w-full max-w-5xl">
          <div className="flex justify-between p-4 md:p-5 border-b rounded-t">
            <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
              Loading...
            </h3>
            <button
              type="button"
              className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 inline-flex justify-center items-center"
              data-modal-hide="default-modal"
              onClick={close}>
              <svg
                className="w-3 h-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14">
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
          </div>

          <div className="space-y-4 h-[450px] w-full animate-pulse">
            <svg
              height="auto"
              width="100%"
              viewBox="0 0 2500 750"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M0 413.116c21.8 12.96 65.4 66.056 109 64.805 43.6-1.251 65.4-91.044 109-71.06 43.6 19.984 65.4 232.87 109 170.978 43.6-61.891 65.4-387.003 109-480.436 43.6-93.433 65.4-44.006 109 13.27 43.6 57.276 65.4 239.601 109 273.111 43.6 33.51 65.4-58.98 109-105.561 43.6-46.58 65.4-154.25 109-127.342 43.6 26.909 65.4 191.794 109 261.885 43.6 70.09 65.4 136.257 109 88.568 43.6-47.688 65.4-265.895 109-327.01 43.6-61.116 65.4-55.93 109 21.433 43.6 77.362 65.4 302.442 109 365.378 43.6 62.936 65.4-4.354 109-50.698 43.6-46.344 65.4-158.24 109-181.023 43.6-22.784 65.4 80.01 109 67.105 43.6-12.906 65.4-116.045 109-131.634 43.6-15.589 65.4 22.497 109 53.69 43.6 31.194 65.4 131.963 109 102.276 43.6-29.687 65.4-210.565 109-250.713 43.6-40.147 65.4-16.846 109 49.975 43.6 66.821 65.4 208.386 109 284.131 43.6 75.746 87.2 75.678 109 94.597"
                fill="none"
                stroke="rgb(0, 143, 251)"
                stroke-width="10"
              />
            </svg>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      ref={modalRef}
      id="default-modal"
      tabIndex="-1"
      aria-hidden="true"
      className="overflow-y-auto overflow-x-hidden fixed inset-0 z-50 flex justify-center p-6 items-center bg-black bg-opacity-50 backdrop-blur-lg cursor-pointer">
      <div className="relative bg-white dark:bg-gray-800 rounded-lg shadow-md w-full max-w-5xl cursor-default">
        <div className="flex justify-between p-4 md:p-5 border-b rounded-t">
          <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
            {stationName} - {dataInformation.name}
          </h3>
          <button
            type="button"
            className="text-gray-400 dark:text-white bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 inline-flex justify-center items-center"
            data-modal-hide="default-modal"
            onClick={close}>
            <svg
              className="w-3 h-3"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 14">
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
              />
            </svg>
            <span className="sr-only">Close modal</span>
          </button>
        </div>

        <div className="p-4 md:p-5 space-y-4">
          <Chart
            options={chartData.options}
            series={chartData.series}
            type={chartData.type}
          />
        </div>
      </div>
    </div>
  );
};

export default LineGraphModal;
